<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import ProductService from '@/services/product'

export default {
  page: {
    title: "Marcas",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      title: "Marcas",
      brands: [],
      modalError: '',
      fields: [
        { key: 'description', sortable: true, label: 'Descrição' },
        { key: 'actions', label: 'Ações' },
      ],

      brand: {
        description: '',
        id: '',
      },

      searchValue: '',
      count: 0,
      currentCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 20,
    };
  },
  computed: {
    rows() {
      return this.brands.length;
    },
    startIndex() {
      return (this.currentPage * this.perPage) - this.perPage + 1;
    },
    endIndex() {
      const endIndex = (this.currentPage * this.perPage);
      if (this.brands.length < endIndex) {
        return this.brands.length;
      }

      return endIndex;
    }
  },
  methods: {
    search() {

    },
    load(loader = null) {
      if (!loader) {
        loader = this.$loading.show();
      }

      ProductService.getBrands().then(res => {
        this.brands = res.data;

        loader.hide();
      })
    },
    newBrand() {
      this.brand = {
        description: '',
      }
      this.modalError = '';

      this.$bvModal.show('modal-edit');
    },
    editBrand(item) {
      this.brand = {
        _id: item._id,
        description: item.description,
      }
      this.modalError = '';

      this.$bvModal.show('modal-edit');
    },
    async saveBrand() {
      this.modalError = '';
      if (!this.brand.description) {
        return this.modalError = 'Informe a descrição';
      }

      this.$bvModal.hide('modal-edit');

      let loader = this.$loading.show();

      if (this.brand._id) {
        await ProductService.updateBrand(this.brand);
      } else {
        await ProductService.createBrand(this.brand);
      }

      this.$toast.open('Dados atualizados com sucesso');

      this.load(loader);
    },
    async remove() {
      this.$bvModal.hide('modal-remove');

      let loader = this.$loading.show();
      await ProductService.removeBrand(this.brand);

      this.$toast.open('Registro removido com sucesso');

      this.load(loader);
    },
    removeBrand(item) {
      this.brand = {
        _id: item._id,
        description: item.description,
      }

      this.$bvModal.show('modal-remove');
    }
  },
  mounted() {
    this.load();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{title}}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body"> 
            <div class="row mb-2">
              <div class="col-sm-12">
                <div class="text-sm-right">
                  <button type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="newBrand">
                    <i class="mdi mdi-plus mr-1"></i> Nova Marca
                  </button>
                </div>
              </div>
            </div>

            <div class="table-responsive mb-0">
              <b-table
                show-empty
                emptyText="Nenhum registro para exibir"
                :items="brands"
                :fields="fields"
                responsive="sm"
                head-variant="light"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template v-slot:cell(actions)="row">
                  <b-dropdown class="card-drop" variant="white" dropleft toggle-class="p-0">
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal font-size-18"></i>
                    </template>
                    <b-dropdown-item href="javascript: void(0);" @click="editBrand(row.item)">
                      <i class="fas fa-edit text-warning mr-2 mt-2"></i>
                      Editar
                    </b-dropdown-item>
                    <b-dropdown-item v-b-modal.modal-remove @click="removeBrand(row.item)">
                      <i class="fas fa-trash-alt text-danger mr-2 mt-2"></i>
                      Excluir
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </div>
          </div>
        </div>

        <div class="row justify-content-md-between align-items-md-center mt-4">
          <div class="col-xl-7">Mostrando {{startIndex}} - {{endIndex}} de {{rows}}</div>

          <div class="col-xl-5">
            <div class="text-md-right float-xl-right mt-2 pagination-rounded">
              <b-pagination
                v-model="currentPage" 
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-edit" :title="brand._id ? 'Alterando Marca' : 'Nova Marca'" centered>
      <b-alert v-if="modalError" v-model="modalError" variant="danger" class="mt-3" dismissible>{{modalError}}</b-alert>

      <div class="form-group">
        <label for="brandDescription">Descrição</label>
        <input id="brandDescription" name="brandDescription" type="text" class="form-control" v-model="brand.description" />
      </div>
      
      <template v-slot:modal-footer>
        <b-button variant="link" @click="$bvModal.hide('modal-edit')">Cancelar</b-button>
        <b-button variant="primary" @click="saveBrand()">
          Salvar
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-remove"
      title="Excluir Registro?"
      title-class="font-18"
      centered
    >
      <p>Confirma a exclusão da marca <b>{{brand.description}}</b>? Todos os produtos com essa atribuição serão automaticamente atualizados.</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-remove')">Cancelar</b-btn>
        <b-btn variant="primary" @click="remove">Excluir</b-btn>
      </div>
    </b-modal>
  </Layout>
</template>
